import AppDashboardLayoutSection from "@/apps/list/app/common/settings/AppDashboardLayoutSection"
import AppSettingsForm from "@/apps/list/app/common/settings/AppSettingsForm"
import { DashboardAppSetupModalContentFragment$key } from "@/apps/list/app/dashboard/__generated__/DashboardAppSetupModalContentFragment.graphql"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON, APP_KIND_LABELS } from "@/apps/util/appConstants"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

interface Props extends TestIDProps {
  appId?: GlobalID
  appKey?: DashboardAppSetupModalContentFragment$key | null
}

function DashboardAppSetupModalContent({
  testid = "DashboardAppSetupModalContent",
  appKey,
}: Props) {
  const { closeModal, setOpenModalState } = useActiveAppModal()
  const history = useHistory()

  const app = useFragment<DashboardAppSetupModalContentFragment$key>(
    graphql`
      fragment DashboardAppSetupModalContentFragment on ProductApp {
        id
        forYouDashboard {
          id
        }
      }
    `,
    appKey || null
  )

  const createForm = useAddAppFormStore({
    kind: "dashboard",
    badge: {
      kind: "icon",
      icon: APP_KIND_ICON.dashboard,
      color: "#ffffff00",
    },
    customAppTitle: "",
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
    dashboardLayout: "one_column",
  })
  const editForm = useEditAppFormStore(app?.id)
  const form = app ? editForm : createForm

  const isForYouDashboard = Boolean(app?.forYouDashboard?.id)

  return (
    <AppSettingsForm
      form={form}
      onSubmit={app ? handleEditApp : handleAddApp}
      onClose={app ? closeModal : () => setOpenModalState({ kind: "add-app" })}
      mode={app ? "edit" : "add"}
      hideSectionSelector={isForYouDashboard}
      formSectionBody={<AppDashboardLayoutSection testid={testid} form={form} />}
      visibilityConfig={{ hide: isForYouDashboard }}
    />
  )

  async function handleAddApp() {
    const {
      embedCode: _embedCode,
      scormFileId: _scormFileId,
      ...state
    } = createForm.state
    const { didSave, response } = await createForm.submit(state, {
      connections: [],
      variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
    })
    if (!didSave || !response?.node) return

    displaySuccessToast({
      message: `${APP_KIND_LABELS.dashboard} app created!`,
      testid: `${testid}.success-message`,
    })
    closeModal()
    history.push(
      generatePath(ROUTE_NAMES.COMMUNITY.DASHBOARD_APP.DETAIL, {
        appId: response.node.id,
      })
    )
  }

  async function handleEditApp() {
    const {
      embedCode: _embedCode,
      scormFileId: _scormFileId,
      kind: _kind,
      appDirectoryItems: _appDirectoryItems,
      ...changedState
    } = editForm.changedState

    const visibilitySettingsChanged =
      editForm.changedState.visibility ||
      editForm.changedState.visibilityGroups ||
      editForm.changedState.visibilityMembers

    const { didSave, response } = await editForm.submit(
      {
        id: editForm.state.id,
        ...changedState,
        badge: editForm.changedState.badge ? editForm.state.badge : undefined,
        visibility: visibilitySettingsChanged ? editForm.state.visibility : undefined,
        visibilityGroups: visibilitySettingsChanged
          ? editForm.state.visibilityGroups
          : undefined,
        visibilityMembers: visibilitySettingsChanged
          ? editForm.state.visibilityMembers
          : undefined,
      },
      {
        updater: (store, { response: { node } }) => {
          // If changed from 2->1 columns apps could be moved so invalidate the dashboard
          if (changedState.dashboardLayout !== "one_column") return
          if (!node?.dashboard) return
          const dashboardRecord = store.get(node.dashboard.id)
          dashboardRecord?.invalidateRecord()
        },
      }
    )
    if (!didSave || !response?.node) return
    displaySuccessToast({
      message: isForYouDashboard
        ? "Updated!"
        : `${APP_KIND_LABELS.dashboard} app updated!`,
      testid: "AddApp.success-toast",
    })
    closeModal()
  }
}

export default observer(DashboardAppSetupModalContent)
