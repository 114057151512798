import CreateBadgeModalButton from "@/admin/experiences/badges/CreateBadgeModalButton"
import {
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import { AppIconKind } from "@/apps/util/activeAppModalContext"
import { useAppLevel } from "@/apps/util/appLevelContext"
import NavSectionSelect from "@/organization/common/sidebar/nav-section/NavSectionSelect"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoFormControl,
  DiscoIcon,
  DiscoInput,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import { observer } from "mobx-react-lite"
import React, { ReactNode } from "react"

export type AppSpecialIconKind = Extract<
  AppIconKind,
  | "icon-discord"
  | "icon-airtable"
  | "icon-google-doc"
  | "icon-google-form"
  | "icon-typeform"
  | "icon-whereby"
  | "icon-figma"
  | "icon-miro"
  | "icon-page"
  | "icon-slack"
>
export const AppIcons: Record<AppSpecialIconKind, ReactNode> = {
  "icon-discord": <DiscoIcon icon={"icon-discord"} />,
  "icon-airtable": <DiscoIcon icon={"icon-airtable"} />,
  "icon-google-doc": <DiscoIcon icon={"icon-google-doc"} />,
  "icon-google-form": <DiscoIcon icon={"icon-google-form"} />,
  "icon-typeform": <DiscoIcon icon={"icon-typeform"} />,
  "icon-whereby": <DiscoIcon icon={"icon-whereby"} />,
  "icon-figma": <DiscoIcon icon={"icon-figma"} />,
  "icon-miro": <DiscoIcon icon={"icon-miro"} />,
  "icon-page": <DiscoIcon icon={"iconsax.document-1"} />,
  "icon-slack": <DiscoIcon icon={"icon-slack"} />,
}
interface Props {
  form: LegacyCreateSetupAppModalFormStore | LegacyEditSetupAppModalFormStore
  placeholder?: string
  hideIconAndTitle?: boolean
  hideSectionSelector?: boolean
}

function LegacyAppIconAndTitleFormSection({
  form,
  placeholder,
  hideIconAndTitle = false,
  hideSectionSelector = false,
}: Props) {
  const { product } = useAppLevel()
  const classes = useStyles()

  const iconAndTitle = hideIconAndTitle ? null : (
    <DiscoFormControl
      label={<DiscoText variant={"body-sm"}>{"Icon and title"}</DiscoText>}
      errorMessages={form?.errorsByField.customAppTitle}
      marginBottom={2}
    >
      <DiscoInput
        data-testid={"AppIconAndTitleFormSection.Input.app-name"}
        name={"title"}
        value={form.state.customAppTitle}
        placeholder={placeholder}
        onChange={handleTitleChange}
        startAdornment={
          <DiscoTooltip content={"Customize"}>
            <CreateBadgeModalButton badge={form.state.badge!} />
          </DiscoTooltip>
        }
        padding={"0 10px 0 0"}
      />
    </DiscoFormControl>
  )
  // Product level apps don't see the section chooser
  if (product) return iconAndTitle

  const sectionSelect = hideSectionSelector ? null : (
    <DiscoFormControl
      label={<DiscoText variant={"body-sm"}>{"Section"}</DiscoText>}
      tooltip={"Choose a section for this app to go into (if any)"}
      errorMessages={form?.errorsByField.navSectionId}
      marginBottom={2}
    >
      <NavSectionSelect
        testid={"AppIconAndTitleFormSection.nav-section"}
        value={form.state.navSectionId}
        onChange={(id) => (form.state.navSectionId = id)}
      />
    </DiscoFormControl>
  )

  return iconAndTitle && sectionSelect ? (
    <div className={classes.split}>
      {iconAndTitle}
      {sectionSelect}
    </div>
  ) : (
    iconAndTitle || sectionSelect
  )

  function handleTitleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    form.state.customAppTitle = e.target.value
  }
}

const useStyles = makeUseStyles((theme) => ({
  split: {
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
  },
}))

export default observer(LegacyAppIconAndTitleFormSection)
