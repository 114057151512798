/**
 * @generated SignedSource<<860fbc64a32e4dd7a7d1377e0faffb90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "dashboard" | "events" | "group_directory" | "link" | "member_directory" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppsSidebarList_OrganizationFragment$data = {
  readonly apps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly collection: {
          readonly id: string;
        } | null;
        readonly contentUsage: {
          readonly id: string;
        } | null;
        readonly customAppTitle: string | null;
        readonly customAppUrl: string | null;
        readonly feed: {
          readonly id: string;
          readonly name: string;
          readonly viewerPermissions: ReadonlyArray<string>;
          readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
        } | null;
        readonly forYouDashboard: {
          readonly id: string;
        } | null;
        readonly id: string;
        readonly kind: ProductAppKind;
        readonly linkProduct: {
          readonly slug: string;
        } | null;
        readonly product: {
          readonly name: string;
          readonly slug: string;
          readonly " $fragmentSpreads": FragmentRefs<"appLevelContextFragment" | "usePermissionsFragment">;
        } | null;
        readonly productApps: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly chatChannel: {
                readonly externalChannelId: string;
              } | null;
              readonly id: string;
            };
          }>;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"AppSidebarItemFragment" | "ChatChannelSettingsModalButtonFragment" | "ChatChannelSideBarItemFragment" | "CollectionAppSettingsButtonFragment" | "ContentAppSettingsButtonFragment" | "DashboardAppSettingsButtonFragment" | "EventsAppSettingsButtonFragment" | "FeedAppSettingsButtonFragment" | "GenericAppSettingsButtonFragment" | "LinkAppSettingsButtonFragment" | "MembersAppSettingsButtonFragment" | "NavFolderSettingsDropdownFragment">;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "AppsSidebarList_OrganizationFragment";
};
export type AppsSidebarList_OrganizationFragment$key = {
  readonly " $data"?: AppsSidebarList_OrganizationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppsSidebarList_OrganizationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "usePermissionsFragment"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "apps"
        ]
      }
    ]
  },
  "name": "AppsSidebarList_OrganizationFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "apps",
      "args": null,
      "concreteType": "ProductAppNodeConnection",
      "kind": "LinkedField",
      "name": "__AppsSidebarList_OrganizationFragment__apps_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAppNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductApp",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customAppUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customAppTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Feed",
                  "kind": "LinkedField",
                  "name": "feed",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "viewerPermissions",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "appLevelContextFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Collection",
                  "kind": "LinkedField",
                  "name": "collection",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "contentUsage",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "linkProduct",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductAppNodeConnection",
                  "kind": "LinkedField",
                  "name": "productApps",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductAppNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductApp",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ChatChannel",
                              "kind": "LinkedField",
                              "name": "chatChannel",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "externalChannelId",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Dashboard",
                  "kind": "LinkedField",
                  "name": "forYouDashboard",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AppSidebarItemFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ContentAppSettingsButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LinkAppSettingsButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FeedAppSettingsButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GenericAppSettingsButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CollectionAppSettingsButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChatChannelSettingsModalButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ChatChannelSideBarItemFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NavFolderSettingsDropdownFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EventsAppSettingsButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MembersAppSettingsButtonFragment"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DashboardAppSettingsButtonFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "ffdc5a5d356cf86bf877b9a7646078a2";

export default node;
