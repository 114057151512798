import { AppsSidebarDragDropProvider_ReorderAppMutation } from "@/apps/sidebar-item/__generated__/AppsSidebarDragDropProvider_ReorderAppMutation.graphql"
import { AppsSidebarDragDropProvider_ReorderNavSectionMutation } from "@/apps/sidebar-item/__generated__/AppsSidebarDragDropProvider_ReorderNavSectionMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { TestIDProps } from "@utils/typeUtils"
import React, { ReactNode, useContext, useState } from "react"
import {
  BeforeCapture,
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql, RecordSourceSelectorProxy } from "relay-runtime"

const TOP_LEVEL_APPS_DROPPABLE_ID = "top-level-apps"
const APPS_DROPPABLE_TYPE = "product_apps"
export const NAV_SECTIONS_DROPPABLE_TYPE = "nav_sections"

type AppsSidebarDragDropProviderProps = {
  children: ReactNode
  productId?: GlobalID
}

export const SidebarDragDropContext = React.createContext({ draggingId: "" })

export default function AppsSidebarDragDropProvider(
  props: AppsSidebarDragDropProviderProps
) {
  const { children, productId } = props
  const activeOrganization = useActiveOrganization()!
  const [draggingId, setDraggingId] = useState("")

  const appForm = useFormStore<AppsSidebarDragDropProvider_ReorderAppMutation>(
    graphql`
      mutation AppsSidebarDragDropProvider_ReorderAppMutation($input: ReorderAppInput!) {
        response: reorderApp(input: $input) {
          node {
            id
            navSectionId
            ordering
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { appId: "", ordering: 0 }
  )
  const navSectionForm =
    useFormStore<AppsSidebarDragDropProvider_ReorderNavSectionMutation>(
      graphql`
        mutation AppsSidebarDragDropProvider_ReorderNavSectionMutation(
          $input: ReorderNavSectionInput!
        ) {
          response: reorderNavSection(input: $input) {
            node {
              id
              ordering
            }
            errors {
              field
              message
            }
          }
        }
      `,
      { navSectionId: "", ordering: 0 }
    )

  return (
    <DragDropContext onDragEnd={handleDragEnd} onBeforeCapture={handleBeforeCapture}>
      <SidebarDragDropContext.Provider value={{ draggingId }}>
        {children}
      </SidebarDragDropContext.Provider>
    </DragDropContext>
  )

  function handleBeforeCapture(before: BeforeCapture) {
    // Set the dragging ID before dragging begins so we can collapse it the dragged
    // item before dimensions are captured
    setDraggingId(before.draggableId)
  }

  function handleDragEnd(result: DropResult) {
    setDraggingId("")
    const { source, destination, type } = result
    if (!destination) return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return
    switch (type) {
      case APPS_DROPPABLE_TYPE:
        handleAppDragEnd(result)
        break
      case NAV_SECTIONS_DROPPABLE_TYPE:
        handleNavSectionDragEnd(result)
        break
    }
  }

  async function handleAppDragEnd(result: DropResult) {
    const { source, draggableId: appId } = result
    const destination = result.destination!

    // Figure out if the app is switching sections or folders
    const fromParent =
      source.droppableId === TOP_LEVEL_APPS_DROPPABLE_ID ? null : source.droppableId
    const toParent =
      destination.droppableId === TOP_LEVEL_APPS_DROPPABLE_ID
        ? null
        : destination.droppableId

    const navSectionId = productId ? null : toParent
    const navFolderId = productId ? toParent : null
    const newIndex = getOffsetDestinationIndex(destination.index)

    await appForm.submit(
      {
        appId,
        ordering: newIndex,
        navSectionId,
        navFolderId,
      },
      {
        optimisticUpdater: storeUpdater,
        updater: (store, { response }) => {
          if (!response.errors) storeUpdater(store)
        },
      }
    )

    function storeUpdater(store: RecordSourceSelectorProxy) {
      const defaultParent = productId || activeOrganization.id
      const sourceParentRecord = store.get(fromParent || defaultParent)
      if (!sourceParentRecord) return
      const sourceConnection = ConnectionHandler.getConnections(
        sourceParentRecord,
        getConnectionKey(fromParent)
      )[0]
      if (!sourceConnection) return

      // If app was dragged within its current section
      if (fromParent === toParent) {
        Relay.reorderEdgeInConnection(sourceConnection, source.index, destination.index)
        return
      }

      // If app was dragged into a different section, we need to move it between connections
      const destinationParentRecord = store.get(toParent || defaultParent)
      if (!destinationParentRecord) return
      const destinationConnection = ConnectionHandler.getConnections(
        destinationParentRecord,
        getConnectionKey(toParent)
      )[0]
      if (!destinationConnection) return
      Relay.moveNodeBetweenConnections(
        sourceConnection,
        destinationConnection,
        appId,
        destination.index
      )
    }

    // We need to add 1 because destination.index only accounts for the draggable apps and does not include the fixed For You app
    // Ie. if you drag an app to the second app in the sidebar, destination.index will be 0, when we really mean to put it at index 1
    function getOffsetDestinationIndex(destinationIndex: number) {
      // We only need to account for the For You app in the destination index app if we're reordering apps within a list of apps that contain the For You app
      // The For You app (as of right now) only exists at the top level of the community sidebar
      if (productId || navSectionId || navFolderId) return destinationIndex

      return activeOrganization.forYouDashboard?.app
        ? destinationIndex + 1
        : destinationIndex
    }

    function getConnectionKey(parent: string | null) {
      if (productId) {
        return parent
          ? "AppsSidebarList_ProductFragment__folderApps"
          : "AppsSidebarList_ProductFragment__productApps"
      }
      return parent
        ? "AppsSidebarList_NavSectionFragment__apps"
        : "AppsSidebarList_OrganizationFragment__apps"
    }
  }

  async function handleNavSectionDragEnd(result: DropResult) {
    const { source, destination, draggableId } = result
    await navSectionForm.submit(
      {
        navSectionId: draggableId,
        ordering: destination!.index,
      },
      {
        optimisticUpdater: storeUpdater,
        updater: (store, { response }) => {
          if (!response.errors) storeUpdater(store)
        },
      }
    )
    function storeUpdater(store: RecordSourceSelectorProxy) {
      const organizationRecord = store.get(activeOrganization.id)
      if (!organizationRecord) return
      ConnectionHandler.getConnections(
        organizationRecord,
        "CommunitySideBar__communityNavSections"
      ).forEach((connection) => {
        Relay.reorderEdgeInConnection(connection, source.index, destination!.index)
      })
    }
  }
}

type AppsSidebarAppsDragDropProps<T extends { id: string }> = TestIDProps & {
  navSectionId?: GlobalID
  navFolderId?: GlobalID
  apps: T[]
  children: (
    app: T,
    dragHandleProps: DraggableProvidedDragHandleProps | undefined,
    isDragging: boolean
  ) => ReactNode
  disabled: boolean
  emptyState: ReactNode
}

export function AppsSidebarAppsDragDrop<T extends { id: string }>(
  props: AppsSidebarAppsDragDropProps<T>
) {
  const {
    navSectionId,
    navFolderId,
    apps,
    children,
    disabled,
    emptyState,
    testid = "AppsSidebarAppsDragDrop",
  } = props
  const { draggingId } = useContext(SidebarDragDropContext)

  return (
    <Droppable
      droppableId={navFolderId || navSectionId || TOP_LEVEL_APPS_DROPPABLE_ID}
      type={APPS_DROPPABLE_TYPE}
      renderClone={(draggableProvided, snapshot, rubric) => (
        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
          {children(
            apps[rubric.source.index],
            draggableProvided.dragHandleProps,
            snapshot.isDragging
          )}
        </div>
      )}
    >
      {(droppableProvided) => (
        <div
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
          data-testid={testid}
        >
          {apps.length
            ? apps.map((app, i) => (
                <Draggable
                  key={app.id}
                  draggableId={app.id}
                  index={i}
                  isDragDisabled={disabled}
                >
                  {(draggableProvided) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                    >
                      {children(
                        app,
                        draggableProvided.dragHandleProps,
                        app.id === draggingId
                      )}
                    </div>
                  )}
                </Draggable>
              ))
            : emptyState}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

type AppsSidebarNavSectionsDragDropProps<T extends { id: string }> = {
  navSections: T[]
  children: (
    section: T,
    dragHandleProps: DraggableProvidedDragHandleProps | undefined,
    isDragging: boolean,
    index: number
  ) => ReactNode
  disabled: boolean
}

export function AppsSidebarNavSectionsDragDrop<T extends { id: string }>(
  props: AppsSidebarNavSectionsDragDropProps<T>
) {
  const { navSections, children, disabled } = props
  const { draggingId } = useContext(SidebarDragDropContext)

  return (
    <Droppable droppableId={"nav-sections"} type={NAV_SECTIONS_DROPPABLE_TYPE}>
      {(droppableProvided) => (
        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
          {navSections.map((section, i) => (
            <Draggable
              key={section.id}
              draggableId={section.id}
              index={i}
              isDragDisabled={disabled}
            >
              {(draggableProvided) => (
                <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                >
                  {children(
                    section,
                    draggableProvided.dragHandleProps,
                    section.id === draggingId,
                    i
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
