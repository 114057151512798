/**
 * @generated SignedSource<<af61cf211b6994b1a82f807baf4b05bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardAppSetupModalContentFragment$data = {
  readonly forYouDashboard: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "DashboardAppSetupModalContentFragment";
};
export type DashboardAppSetupModalContentFragment$key = {
  readonly " $data"?: DashboardAppSetupModalContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardAppSetupModalContentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardAppSetupModalContentFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "forYouDashboard",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "2bae77e220b4dcc60bda4ce7a2a32a32";

export default node;
