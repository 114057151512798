import Badge from "@/admin/experiences/badges/Badge"
import ToggleAppStatusButton from "@/apps/actions/ToggleAppStatusButton"
import RemoveAppButton from "@/apps/remove-app-button/RemoveAppButton"
import { AppSidebarItemFragment$key } from "@/apps/sidebar-item/__generated__/AppSidebarItemFragment.graphql"
import {
  APP_KIND_LABELS,
  APP_REMOVABLE_KINDS,
  CAN_DISABLE_APP_KINDS,
} from "@/apps/util/appConstants"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import CommunitySidebarItem, {
  CommunitySideBarItemProps,
} from "@/organization/common/sidebar/CommunitySidebarItem"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoIcon, DiscoSideBarItemSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import classNames from "classnames"
import React from "react"
import { graphql, useFragment } from "react-relay"

export interface AppSidebarItemProps
  extends Omit<
    CommunitySideBarItemProps,
    "button" | "classes" | "color" | "name" | "to"
  > {
  to?: string
  appKey: AppSidebarItemFragment$key
  settingsButton?: React.ReactNode
  name?: string
  hideLeftIcon?: boolean
  disableNavigation?: boolean
}

function AppSidebarItem({
  appKey,
  testid,
  to,
  notificationConfig,
  externalLink,
  settingsButton,
  items,
  name,
  hideLeftIcon,
  selected,
  children,
  disableNavigation = false,
  showItemsRoute,
  ...rest
}: AppSidebarItemProps) {
  const app = useFragment<AppSidebarItemFragment$key>(
    graphql`
      fragment AppSidebarItemFragment on ProductApp {
        kind
        status
        visibility
        customAppTitle
        badge {
          ...BadgeFragment
        }
        product {
          ...usePermissionsFragment
        }
        forYouDashboard {
          id
        }
        ...RemoveAppButtonFragment
        ...ToggleAppStatusButtonFragment
      }
    `,
    appKey
  )

  const isForYouDashboard = Boolean(app.forYouDashboard?.id)
  const labels = useLabels()
  const activeOrganization = useActiveOrganization()
  const permissions = usePermissions(app.product || activeOrganization)
  const canManage = permissions.has("apps.manage")
  const removeableKinds = APP_REMOVABLE_KINDS
  const removable = canManage && removeableKinds.has(app.kind) && !isForYouDashboard
  const canDisable = canManage && CAN_DISABLE_APP_KINDS.has(app.kind)
  const showOverflowItems = canManage && (removable || canDisable || settingsButton)

  const isActive = app.status === "active"
  const classes = useStyles({ disableNavigation })
  const showOnHoverClasses = useShowOnHoverStyles()

  if (app.visibility === "admin" && !canManage) return null

  return (
    <CommunitySidebarItem
      className={classNames(showOnHoverClasses.hoverable, classes.sidebarItem)}
      testid={testid}
      to={disableNavigation ? undefined : to}
      name={getName()}
      leftIcon={
        hideLeftIcon ? null : (
          <Badge
            isSideBar={true}
            isSelected={getIsSelected()}
            badgeKey={app.badge}
            size={24}
          />
        )
      }
      items={items}
      notificationConfig={notificationConfig}
      overflowItems={
        showOverflowItems && (
          <>
            {settingsButton}
            {canDisable && (
              <ToggleAppStatusButton appKey={app}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    testid={`${testid}.more-actions.toggle-status`}
                    title={`${isActive ? "Hide" : "Unhide"} for ${
                      labels.admin_member.plural
                    }`}
                  />
                )}
              </ToggleAppStatusButton>
            )}
            {removable && (
              <RemoveAppButton appKey={app}>
                {(buttonProps) => (
                  <DiscoDropdownItem
                    {...buttonProps}
                    testid={`${testid}.more-actions.remove`}
                    title={"Remove"}
                  />
                )}
              </RemoveAppButton>
            )}
          </>
        )
      }
      externalLink={externalLink}
      selected={getIsSelected()}
      rightContent={
        !isActive && <DiscoIcon icon={"eye-off"} className={classes.inactiveIcon} />
      }
      showItemsRoute={showItemsRoute}
      {...rest}
    >
      {children}
    </CommunitySidebarItem>
  )

  function getName() {
    if (name) return name
    if (app.customAppTitle) return app.customAppTitle

    switch (app.kind) {
      case "members":
        return labels.product_member.plural
      case "organization_members":
        return labels.organization_member.plural
      case "curriculum":
        return labels.curriculum.singular
      case "organization_events":
        return "Community Events"
      default:
        return APP_KIND_LABELS[app.kind]
    }
  }

  function getIsSelected() {
    if (selected) return true
    if (!to) return false
    return location.pathname === to || location.pathname.startsWith(`${to}/`)
  }
}

type StyleProps = {
  disableNavigation: boolean
}

const useStyles = makeUseStyles((theme) => ({
  sidebarItem: ({ disableNavigation }: StyleProps) => ({
    ...styleIf(disableNavigation, {
      cursor: "unset",
    }),
  }),
  inactiveIcon: {
    // Increase specificity to override icon color when not selected
    ".DiscoSideBarItem__right-content svg&": {
      color: theme.palette.groovy.neutral[500],
    },
    opacity: 0.5,
  },
}))

export default Relay.withSkeleton<AppSidebarItemProps>({
  component: AppSidebarItem,
  skeleton: DiscoSideBarItemSkeleton,
})
