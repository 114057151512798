/**
 * @generated SignedSource<<2eecab5400d26d716f8fa90183167a42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardAppSetupModalFragment$data = {
  readonly customAppTitle: string | null;
  readonly forYouDashboard: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardAppSetupModalContentFragment">;
  readonly " $fragmentType": "DashboardAppSetupModalFragment";
};
export type DashboardAppSetupModalFragment$key = {
  readonly " $data"?: DashboardAppSetupModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardAppSetupModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardAppSetupModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "forYouDashboard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardAppSetupModalContentFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "694874a10deeb29f7b3a7b03fbdaf029";

export default node;
