import {
  LegacyCreateSetupAppModalFormStore,
  LegacyEditSetupAppModalFormStore,
} from "@/apps/list/modal/SetupAppModal"
import oneColumnImage from "@/core/ui/images/dashboard-layout-1-column.png"
import twoColumnImage from "@/core/ui/images/dashboard-layout-2-column.png"
import { DashboardLayout } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface Props extends TestIDProps {
  form: LegacyCreateSetupAppModalFormStore | LegacyEditSetupAppModalFormStore
}

function AppDashboardLayoutSection({ testid, form }: Props) {
  const classes = useStyles()

  return (
    <DiscoFormControl label={<DiscoText variant={"body-sm"}>{"Page Layout"}</DiscoText>}>
      <RadioGroup
        value={form.state.dashboardLayout}
        onChange={(_, v) => (form.state.dashboardLayout = v as DashboardLayout)}
        className={classes.radioGroup}
      >
        <DiscoRadioWithImage
          testid={`${testid}.layout.one-column`}
          label={"1-column layout"}
          sublabel={<DiscoImage src={oneColumnImage} className={classes.img} />}
          value={"one_column"}
          checked={form.state.dashboardLayout === "one_column"}
        />
        <DiscoRadioWithImage
          testid={`${testid}.layout.two-column`}
          label={"2-column layout"}
          sublabel={<DiscoImage src={twoColumnImage} className={classes.img} />}
          value={"two_column"}
          checked={form.state.dashboardLayout === "two_column"}
        />
      </RadioGroup>
    </DiscoFormControl>
  )
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  img: {
    border: `1px solid ${theme.palette.groovy.neutral[200]}`,
  },
}))

export default observer(AppDashboardLayoutSection)
