import DashboardAppSetupModalContent from "@/apps/list/app/dashboard/DashboardAppSetupModalContent"
import { DashboardAppSetupModalFragment$key } from "@/apps/list/app/dashboard/__generated__/DashboardAppSetupModalFragment.graphql"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_LABELS } from "@/apps/util/appConstants"
import { DiscoTextButton } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  appKey?: DashboardAppSetupModalFragment$key
}

function DashboardAppSetupModal({ testid, appKey }: Props) {
  const { openModalState } = useActiveAppModal()

  const app = useFragment<DashboardAppSetupModalFragment$key>(
    graphql`
      fragment DashboardAppSetupModalFragment on ProductApp {
        customAppTitle
        forYouDashboard {
          id
        }
        ...DashboardAppSetupModalContentFragment
      }
    `,
    appKey || null
  )

  const isForYouDashboard = Boolean(app?.forYouDashboard?.id)

  return (
    <SetupAppModal
      testid={`${testid}.dashboard`}
      isModalOpen={openModalState?.kind === "dashboard"}
      title={isForYouDashboard ? "Setup" : `${APP_KIND_LABELS.dashboard} App Setup`}
      body={<DashboardAppSetupModalContent testid={`${testid}.dashboard`} appKey={app} />}
      leftSideFooter={
        !isForYouDashboard && (
          <DiscoTextButton
            leftIcon={"external-link"}
            to={
              // TODO: Update with Dashboard Templates KB link when it exists
              "https://support.disco.co"
            }
            target={"_blank"}
          >
            {`${APP_KIND_LABELS.dashboard} Templates`}
          </DiscoTextButton>
        )
      }
    />
  )
}

export default DashboardAppSetupModal
